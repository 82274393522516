<template>
  <div :class="type" class="search-hints">
    <input
      id="ssearch"
      ref="ssearch"
      v-model="searchQuery"
      :class="{ active: searchQuery }"
      @input="trottled"
      @focus="focused = true"
      @blur="focused = false"
      type="search"
      name="ssearch"
      autocomplete="off"
      :placeholder="type === 'db' ? 'Какую игру ищем?' : 'Во что будем играть?'"
    >
    <div v-if="searchQuery" class="strict">
      <input v-model="strict" type="checkbox" id="cbx" style="display:none"/>
      <label for="cbx" class="toggle"><span></span></label>
    </div>
    <div v-if="searchQuery.length > 2" class="datalist">
      <div v-if="!searchHints.length" class="no-match">
        <span>Ничего не найдено</span>
      </div>
      <ul v-else>
        <nuxt-link
          v-for="(item, i) in searchHints"
          :to="`/games/${item.id}-${item.slug}`"
          :key="`sh-${i}`"
          tag="li"
        >
          <div class="poster">
            <img :src="item.poster" alt="">
          </div>
          <div class="ph">
            <div v-if="item.platforms.length" class="platforms">
              <div v-for="p in item.platforms" :key="`sh-platform-${p.platform.name}`" :class="p.platform.slug" />
            </div>
            <strong>{{ item.title }}</strong>
            <span v-if="item.year">{{ item.year }}</span>
            <span v-for="g in item.genres" :key="`sh-genre-${g.id}`">
              {{ filterGenres(g.name) }}
            </span>
          </div>
        </nuxt-link>
      </ul>
      <div v-if="searchHints.length >= 50" class="more">
        <button @click="getData(++page)">Показать еще</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { type: { type: String, require: true, default: 'db' } },
  watch: {
    strict() {
      this.searchHints = []
      this.getData()
    },
    route() {
      this.searchQuery = ''
      this.searchHints = []
    }
  },
  computed: {
    route() {
      return this.$route.fullPath
    }
  },
  data() {
    return {
      searchQuery: '',
      searchHints: [],
      page: 0,
      strict: false
    }
  },
  methods: {
    filterGenres(val) {
      const genres = [
        {
          name: {
            en: 'Action',
            ru: 'Экшен',
          },
        },
        {
          name: {
            en: 'Adventure',
            ru: 'Приключения',
          },
        },
        {
          name: {
            en: 'Arcade',
            ru: 'Аркада',
          },
        },
        {
          name: {
            en: 'Board Games',
            ru: 'Настольные',
          },
        },
        {
          name: {
            en: 'Card',
            ru: 'Карточные',
          },
        },
        {
          name: {
            en: 'Casual',
            ru: 'Казуальные',
          },
        },
        {
          name: {
            en: 'Educational',
            ru: 'Обучение',
          },
        },
        {
          name: {
            en: 'Family',
            ru: 'Семейный',
          },
        },
        {
          name: {
            en: 'Fighting',
            ru: 'Файтинг',
          },
        },
        {
          name: {
            en: 'Indie',
            ru: 'Инди',
          },
        },
        {
          name: {
            en: 'Massively Multiplayer',
            ru: 'Мультиплеер',
          },
        },
        {
          name: {
            en: 'Platformer',
            ru: 'Платформер',
          },
        },
        {
          name: {
            en: 'Puzzle',
            ru: 'Пазл',
          },
        },
        {
          name: {
            en: 'RPG',
            ru: 'РПГ',
          },
        },
        {
          name: {
            en: 'Racing',
            ru: 'Гонки',
          },
        },
        {
          name: {
            en: 'Shooter',
            ru: 'Шутер',
          },
        },
        {
          name: {
            en: 'Simulation',
            ru: 'Симулятор',
          },
        },
        {
          name: {
            en: 'Sports',
            ru: 'Спорт',
          },
        },
        {
          name: {
            en: 'Strategy',
            ru: 'Стратегия',
          },
        }
      ]

      const findGenre = genres.find(el => el.name.en === val)
      return findGenre.name[this.$store.state.lang]
    },
    trottled() {
      if (!this.searchQuery.trim()) return
      let oldValue = ''
      setTimeout(() => {
        if (
          oldValue === this.searchQuery &&
          this.searchQuery !== '' &&
          this.searchQuery.length > 2
        ) {
          this.getData()
        }
      }, 200)
      oldValue = this.searchQuery
    },
    async getData() {
      const { data } = await this.$axios.get(`/api/search?page=${this.page}&strict=${this.strict}&value=${this.searchQuery.trim()}`)

      let arr = []

      if (data.length) {
        arr = this.page ? this.searchHints.concat(data) : data
      }

      this.searchHints = arr
    }
  }
}
</script>
